'use client';

import { Item } from '@/api';
import Container from '@/components/Layout/Container';
import ProductTile from '@/components/ecommerce/ProductTile';
import Select from 'react-select';
import Pagination from '@/components/general/Pagination';
import { SearchResponse, searchQueryFn } from '@/queries/search';
import { DisplaySortOptions, DisplaySortOptionsType, SortOptions } from '@/types/API';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';
import Sheet from 'react-modal-sheet';
import ChevronDownGreyIcon from 'public/assets/icons/chevron-down-grey.svg';
import { useMobile } from '@/hooks/useMobile';
import xor from 'lodash.xor';
import SearchFilters, { DefaultFilters } from '../ecommerce/search/SearchFilters';
import Button from '../general/Button';

export interface SearchProps {
    anchor?: string;
    defaultFilter?: DefaultFilters
    defaultSort?: SortOptions
    isDefaultFilterFixed?: boolean
    searchTitle?: string
    hideTitle?: boolean
    searchQuery?: string
    onDefaultFilterChanged?: () => void
}

const DEFAULT_LIMIT = 24;

const arrayEqual = (arr1?: unknown[], arr2?: unknown[]) => arr1 && arr2 && arr1.length === arr2.length && xor(arr1, arr2).length === 0;

export function Search(props: SearchProps) {
    const { anchor, searchTitle, searchQuery, defaultFilter, isDefaultFilterFixed = true, onDefaultFilterChanged, defaultSort, hideTitle = false } = props;
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const searchTopRef = useRef<HTMLDivElement>(null);
    const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_LIMIT);

    const [offset, setOffset] = useState<number>(0);

    const [filtersCounter, setFiltersCounter] = useState<number>(0);

    const [data, setData] = useState<SearchResponse | null>(null);

    const [initDone, setInitDone] = useState<boolean>(false);

    const [artistsFilters, setArtistsFilters] = useState<string[]>([]);
    const [conditionsFilters, setConditionsFilters] = useState<string[]>([]);
    const [genresFilters, setGenresFilters] = useState<string[]>([]);
    const [categoriesFilters, setCategoriesFilters] = useState<string[]>([]);
    const [subCategoriesFilters, setSubCategoriesFilters] = useState<string[]>([]);
    const [collectionsFilters, setCollectionsFilter] = useState<string[]>([]);
    const [hasAuctionFilter, setHasAuctionFilter] = useState<undefined | true>(defaultFilter?.hasAuction ? true : undefined);

    const defaultSortMem = useMemo(() => {
        return (defaultSort ? DisplaySortOptions.find((o) => o.value === defaultSort) : undefined) || DisplaySortOptions[0];
    }, [defaultSort]);

    const [sortBy, setSortBy] = useState<DisplaySortOptionsType | null>(defaultSortMem);
    const isMobile = useMobile();

    useEffect(() => {
        setOffset(0);
    }, [itemsPerPage]);

    const searchMutation = useMutation({
        mutationFn: searchQueryFn,
        onSuccess: (response) => {
            setData(response);
        },
        onError: async (error) => {
        }
    });

    const isMountingRef = useRef(false);

    useEffect(() => {
        if (isMountingRef.current === false) {
            isMountingRef.current = true;
        } else {
            searchTopRef.current?.scrollIntoView({
                block: 'start'
            });
        }
    }, [offset]);

    useEffect(() => {
        const { subcategories, categories, artists, genres, conditions, collections } = (defaultFilter || {});
        setArtistsFilters(searchParams?.get('artists')?.split(',') || artists || []);
        setConditionsFilters(searchParams?.get('conditions')?.split(',') || conditions || []);
        setCategoriesFilters(searchParams?.get('categories')?.split(',') || categories || []);
        setSubCategoriesFilters(searchParams?.get('sub_categories')?.split(',') || subcategories || []);
        setGenresFilters(searchParams?.get('genres')?.split(',') || genres || []);
        setCollectionsFilter(searchParams?.get('collections')?.split(',') || collections || []);

        if (searchParams?.get('sort_by') && Object.values(SortOptions).includes(searchParams?.get('sort_by') as SortOptions)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setSortBy(DisplaySortOptions.find((o) => o.value === (searchParams?.get('sort_by') as SortOptions))!);
        }
        if (searchParams?.get('has_auction') && searchParams?.get('has_auction') === '0') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setHasAuctionFilter(undefined);
        }
        if (searchParams?.get('has_auction') && searchParams?.get('has_auction') === '1') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setHasAuctionFilter(true);
        }

        try {
            const userSettingPageSize = localStorage.getItem('page_size');
            if (userSettingPageSize) {
                setItemsPerPage(parseInt(userSettingPageSize as string, 10));
            }
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
        if (searchParams?.get('offset') && !Number.isNaN(parseInt(searchParams?.get('offset') as string, 10))) {
            setOffset(parseInt(searchParams?.get('offset') as string, 10));
        }
        setInitDone(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!initDone) {
            return;
        }
        const current = new URLSearchParams(Array.from(searchParams?.entries()));

        const genresValue = genresFilters.join(',');
        if (!genresValue || (arrayEqual(genresFilters, defaultFilter?.genres))) {
            current.delete('genres');
        } else {
            current.set('genres', genresValue);
        }

        const artistValue = artistsFilters.join(',');
        if (!artistValue || (arrayEqual(artistsFilters, defaultFilter?.artists))) {
            current.delete('artists');
        } else {
            current.set('artists', artistValue);
        }

        const conditionsValue = conditionsFilters.join(',');
        if (!conditionsValue || (arrayEqual(conditionsFilters, defaultFilter?.conditions))) {
            current.delete('conditions');
        } else {
            current.set('conditions', conditionsValue);
        }

        const categoriesValue = categoriesFilters.join(',');
        if (!categoriesValue || (arrayEqual(categoriesFilters, defaultFilter?.categories))) {
            current.delete('categories');
        } else {
            current.set('categories', categoriesValue);
        }

        const subCategoriesValue = subCategoriesFilters.join(',');
        if (!subCategoriesValue || (arrayEqual(subCategoriesFilters, defaultFilter?.subcategories))) {
            current.delete('sub_categories');
        } else {
            current.set('sub_categories', subCategoriesValue);
        }

        const collectionsValue = collectionsFilters.join(',');
        if (!collectionsValue || (arrayEqual(collectionsFilters, defaultFilter?.collections))) {
            current.delete('collections');
        } else {
            current.set('collections', collectionsValue);
        }

        if (!sortBy?.value || sortBy.value === defaultSortMem.value) {
            current.delete('sort_by');
        } else {
            current.set('sort_by', sortBy.value);
        }

        // eslint-disable-next-line eqeqeq
        if (!!hasAuctionFilter != !!defaultFilter?.hasAuction) {
            current.set('has_auction', hasAuctionFilter ? '1' : '0');
        } else {
            current.delete('has_auction');
        }

        if (itemsPerPage !== DEFAULT_LIMIT) {
            try {
                localStorage.setItem('page_size', `${itemsPerPage}`);
                // eslint-disable-next-line no-empty
            } catch (error) {
            }
        }

        if (offset > 0) {
            current.set('offset', `${offset}`);
        } else {
            current.delete('offset');
        }

        setFiltersCounter(artistsFilters.length + genresFilters.length + conditionsFilters.length + categoriesFilters.length + subCategoriesFilters.length + collectionsFilters.length + (hasAuctionFilter ? 1 : 0));

        const search = current.toString();
        window.history.replaceState({}, '', `${pathname}${search ? `?${search}` : ''}`);
        searchMutation.mutate({
            query: searchQuery || '',
            limit: itemsPerPage,
            offset,
            artists: artistsFilters,
            genres: genresFilters,
            conditions: conditionsFilters,
            categories: categoriesFilters,
            subCategories: subCategoriesFilters,
            has_active_auction: hasAuctionFilter,
            collections: collectionsFilters,
            sortBy: sortBy?.value
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, categoriesFilters, categoriesFilters, artistsFilters, genresFilters, collectionsFilters, conditionsFilters, subCategoriesFilters, offset, sortBy, itemsPerPage, hasAuctionFilter, defaultSortMem]);

    const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false);

    const searchMemo = useMemo(() => <SearchFilters
        defaultFilters={defaultFilter}
        isDefaultFilterFixed={isDefaultFilterFixed}
        meta_available={data?.meta_available}
        artistsFilters={artistsFilters}
        conditionsFilters={conditionsFilters}
        genresFilters={genresFilters}
        collectionsFilters={collectionsFilters}
        categoriesFilters={categoriesFilters}
        subCategoriesFilters={subCategoriesFilters}
        hasAuctionFilter={hasAuctionFilter}
        setHasAuctionsFilter={setHasAuctionFilter}
        setArtistsFilters={(filter) => {
            if (defaultFilter?.artists && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setArtistsFilters(filter);
            }
        }}
        setCollectionsFilters={(filter) => {
            if (defaultFilter?.collections && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setCollectionsFilter(filter);
            }
        }}
        setConditionsFilters={(filter) => {
            setOffset(0);
            if (defaultFilter?.conditions && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setConditionsFilters(filter);
            }
        }}
        setCategoriesFilters={(filter) => {
            if (defaultFilter?.categories && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setCategoriesFilters(filter);
            }
        }}
        setGenresFilters={(filter) => {
            if (defaultFilter?.genres && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setGenresFilters(filter);
            }
        }}
        setSubCategoriesFilters={(filter) => {
            if (defaultFilter?.subcategories && filter.length < 1 && onDefaultFilterChanged) {
                onDefaultFilterChanged();
            } else {
                setOffset(0);
                setSubCategoriesFilters(filter);
            }
        }}
        loading={data === null && searchMutation.isLoading}
        isMobileSheet={showMobileFilters}
    />, [
        defaultFilter, isDefaultFilterFixed, data,
        artistsFilters, conditionsFilters, genresFilters,
        collectionsFilters, categoriesFilters, subCategoriesFilters,
        hasAuctionFilter, searchMutation.isLoading, onDefaultFilterChanged,
        showMobileFilters
    ]);

    return (

        <Container className="!px-0 lg:px-0 max-w-[1280px] mx-auto bg-white">
            {!hideTitle && (
                <div className="pt-[60px] pb-[40px] md:pt-[80px] md:pb-[60px] px-[20px] md:px-[60px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url('/assets/blue_texture.jpeg')" }}>
                    <h1 className="font-heading capitalize text-xl md:text-[58px] text-white z-10">{searchTitle} {data ? `(${data?.pagination?.total})` : ''}</h1>
                </div>
            )}
            <div id={anchor} ref={searchTopRef} className={clsx('flex md:flex-row flex-col justify-between md:px-[60px]', { 'pr-[0px] md:pr-[60px]': !hideTitle })}>
                <div className="hidden md:block">
                    {searchMemo}
                </div>
                <Sheet isOpen={showMobileFilters} onClose={() => setShowMobileFilters(false)} tweenConfig={{ ease: 'easeInOut', duration: 0.4 }}>
                    <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content>
                            <Sheet.Scroller>
                                {searchMemo}
                            </Sheet.Scroller>
                            <div className="pl-[5vw] pr-[5vw] bg-[#F9FAFB] border-t-[1px] py-[20px]">
                                <Button onClick={() => setShowMobileFilters(false)} fullWidth size="lg" className="">Show Results ({data && data.pagination ? data.pagination.total : 0})</Button>
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={() => setShowMobileFilters(false)} />
                </Sheet>
                <div className="w-full max-w-[1060px]">
                    <div className="flex justify-between items-center  pb-3 my-6 border-b-[1px] px-[20px] md:px-0">
                        <button
                            className="block md:hidden rounded-full bg-[#f3f3f5] text-[16px] leading-[16px] font-[500] px-[15px] py-[5px] flex-row items-center"
                            onClick={() => setShowMobileFilters(true)}>{i18next.t('header:search-filters.title')} ({filtersCounter})<ChevronDownGreyIcon className="ml-[5px] w-[16px] h-[16px] rotate-180 inline-block" />
                        </button>
                        <div className="flex items-center">
                            <span className="text-[16px] font-[500]">
                                {i18next.t('header:search-sort.sort')}:
                            </span>
                            <Select<DisplaySortOptionsType>
                                placeholder={i18next.t('header:search-sort.sort-by')}
                                value={sortBy}
                                onChange={(option) => {
                                    setSortBy(option);
                                }}
                                isSearchable={false}
                                options={DisplaySortOptions}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: '0 !important',
                                        boxShadow: '0 !important',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            border: '0 !important'
                                        },
                                    }),
                                    container: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: 16,
                                        fontWeight: 500
                                    }),
                                    indicatorSeparator: (baseStyles, state) => ({
                                        ...baseStyles,
                                        display: 'none'
                                    }),
                                    menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: '1px solid #f2f2f2',
                                        overflow: 'hidden',
                                        borderRadius: 10,
                                        width: 150,
                                        fontWeight: 400,
                                        ...isMobile ? { right: 0 } : { left: 0 }
                                    }),
                                    menuList: (baseStyles) => ({
                                        ...baseStyles,
                                        padding: 0
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        borderBottom: '1px solid #f2f2f2',
                                    })
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#f2f2f2',
                                        primary: '#001f31',
                                    },
                                })}
                            />
                        </div>
                    </div>
                    {searchMutation.isLoading || data === null ?
                        <div className="px-0 py-2 mt-4">
                            <div className="grid md:grid-cols-[25%_25%_25%_25%] tablet:grid-cols-[33%_33%_33%] sm-tablet:grid-cols-[50%_50%] grid-cols-[100%] mb-[24px]">
                                <div className="mx-[12px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="mx-[12px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="mx-[12px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                                <div className="mx-[12px] mb-[24px]">
                                    <Skeleton height={350} count={1} />
                                </div>
                            </div>
                        </div> :
                        <div className={clsx('min-h-[500px] mb-[24px] px-[20px] md:px-[0]', data && data.results && data?.results?.length > 0 && 'grid md:grid-cols-[25%_25%_25%_25%] tablet:grid-cols-[33%_33%_33%] sm-tablet:grid-cols-[50%_50%] grid-cols-[100%]')}>
                            {data && data.results && data?.results?.length > 0 ?
                                data.results.map((searchResult: Item) => (
                                    <div key={searchResult.id} className="md:my-[13px]">
                                        <ProductTile fullWidth data={searchResult} />
                                    </div>
                                )) :
                                <div className=" flex flex-row justify-center w-full py-[140px] text-center">
                                    <div className="w-[271px] text-center">
                                        <h3 className="font-heading font-[400] capitalize text-[40px]">{i18next.t('search.no-results')}</h3>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <Pagination
                        factor={4}
                        itemsPerPageChanged={setItemsPerPage}
                        defaultItemsPerPage={itemsPerPage}
                        data={data?.pagination}
                        offsetChanged={setOffset} />

                </div>
            </div>
        </Container>
    );
}
