import { ItemArtist, ItemCategory, ItemCollection, ItemCondition, ItemGenre, ItemSubCategory } from '@/api';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import ChevronDownGreyIcon from 'public/assets/icons/chevron-down-grey.svg';
import Checkbox from 'react-custom-checkbox';
import { useMeta } from '@/hooks/api/useMeta';
import { MetaAvailableResponse } from '@/queries/search';
import i18next from 'i18n';
import { FunnelIcon } from '@heroicons/react/24/outline';
import Skeleton from 'react-loading-skeleton';
import uniq from 'lodash.uniq';

export type DefaultFilters = { subcategories?: string[], categories?: string[], genres?: string[], artists?: string[], conditions?: string[], collections?: string[], hasAuction?: boolean | null };

type ISearchFiltersProps = {
    defaultFilters?: DefaultFilters;
    isDefaultFilterFixed?: boolean;
    artistsFilters: string[];
    subCategoriesFilters: string[];
    conditionsFilters: string[];
    collectionsFilters: string[];
    genresFilters: string[];
    categoriesFilters: string[];
    hasAuctionFilter?: true;
    meta_available: MetaAvailableResponse;
    setArtistsFilters: (filter: string[]) => void;
    setConditionsFilters: (filter: string[]) => void;
    setCollectionsFilters: (filter: string[]) => void;
    setGenresFilters: (filter: string[]) => void;
    setCategoriesFilters: (filter: string[]) => void;
    setSubCategoriesFilters: (filter: string[]) => void;
    setHasAuctionsFilter: (filter: undefined | true) => void;
    loading: boolean;
    isMobileSheet?: boolean;
};

export const entityName = (entity: ((ItemGenre | ItemCategory | ItemSubCategory | ItemArtist | ItemCondition | ItemCollection) & { available?: number })) => {
    if (entity.available !== undefined) {
        return `${entity.name} (${entity.available})`;
    }
    return entity.name;
};

const SearchFilters = (props: ISearchFiltersProps) => {
    const {
        artistsFilters,
        conditionsFilters,
        collectionsFilters,
        genresFilters,
        categoriesFilters,
        subCategoriesFilters,
        defaultFilters,
        hasAuctionFilter,
        meta_available: metaAvailable,
        setArtistsFilters,
        setCategoriesFilters,
        setConditionsFilters,
        setCollectionsFilters,
        setGenresFilters,
        setSubCategoriesFilters,
        setHasAuctionsFilter,
        loading = false,
        isDefaultFilterFixed = true,
        isMobileSheet
    } = props;
    const { categories, artists, genres, conditions, subCategories, collections } = useMeta(metaAvailable);
    const [showArtists, setShowArtists] = useState<boolean>(false);
    const [showGenres, setShowGenres] = useState<boolean>(false);
    const [showCategories, setShowCategories] = useState<boolean>(false);
    const [showSubCategories, setSubShowCategories] = useState<boolean>(false);
    const [showConditions, setShowConditions] = useState<boolean>(false);
    const [showCollections, setShowCollections] = useState<boolean>(false);

    return (
        <div className={clsx('bg-white p2 md:px-3 flex flex-row space-x-[60px] text-main', !isMobileSheet ? 'mb-24' : '')}>
            <div className="min-w-[160px] w-full md:space-y-[30px]">
                <div className=" md:pb-[18px] md:my-8 border-b-[1px] flex flex-col">
                    <span className="text-[16px] font-[500] hidden md:block">{i18next.t('header:search-filters.title')}</span>
                    <span className="text-[34px] px-[20px] font-heading capitalize font-[400] md:hidden flex flex-row justify-between items-center pb-[20px]">{i18next.t('header:search-filters.title')}<FunnelIcon className="w-[32px]" /></span>
                </div>
                {
                    loading ?
                        <div><Skeleton count={10} /></div> :
                        <div className={clsx('max-h-[65vh] pt-[20px] md:pt-[0px]', !isMobileSheet ? 'overflow-auto' : '')}>
                            <Checkbox
                                borderColor="#D0D5DD"
                                borderWidth={1}
                                className="cursor-pointer ml-[20px] md:ml-[0px]"
                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                label="Active Auction"
                                value
                                checked={hasAuctionFilter}
                                onChange={(checked: boolean) => {
                                    if (checked) {
                                        setHasAuctionsFilter(true);
                                    } else {
                                        setHasAuctionsFilter(undefined);
                                    }
                                }}
                                icon={
                                    <div
                                        style={{
                                            padding: 1,
                                            width: '90%',
                                            height: '90%',
                                            backgroundColor: '#1D2939',
                                            borderRadius: 3
                                        }}
                                    />
                                }
                            />
                            {artists && artists.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setShowArtists(!showArtists)}>{i18next.t('header:search-filters.artists', { count_found: artistsFilters.length > 0 ? `(${artistsFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showArtists && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showArtists &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowArtists-${showArtists ? 'yes' : 'no'}`}>
                                                {artists.map((artist: ItemArtist) => {
                                                    const disabled = defaultFilters?.artists?.includes(artist.slug) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={artist.id}>
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(artist)}
                                                                value={artist.slug}
                                                                checked={artistsFilters.includes(artist.slug)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setArtistsFilters(uniq([...artistsFilters, artist.slug]));
                                                                    } else {
                                                                        setArtistsFilters(artistsFilters.filter((artistSlug: string) => artistSlug !== artist.slug));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }

                            {conditions && conditions.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setShowConditions(!showConditions)}>{i18next.t('header:search-filters.condition', { count_found: conditionsFilters.length > 0 ? `(${conditionsFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showConditions && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showConditions &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowConditions-${showConditions ? 'yes' : 'no'}`}>
                                                {conditions.map((condition: ItemCondition) => {
                                                    const disabled = defaultFilters?.conditions?.includes(condition.name) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={condition.id} className="">
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(condition)}
                                                                checked={conditionsFilters.includes(condition.name)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setConditionsFilters(uniq([...conditionsFilters, condition.name]));
                                                                    } else {
                                                                        setConditionsFilters(conditionsFilters.filter((conditionStr: string) => conditionStr !== condition.name));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }

                            {genres && genres.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setShowGenres(!showGenres)}>{i18next.t('header:search-filters.genres', { count_found: genresFilters.length > 0 ? `(${genresFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showGenres && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showGenres &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowGenres-${showGenres ? 'yes' : 'no'}`}>
                                                {genres.map((genre: ItemGenre) => {
                                                    const disabled = defaultFilters?.genres?.includes(genre.slug) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={genre.id} className="">
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(genre)}
                                                                checked={genresFilters.includes(genre.slug)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setGenresFilters(uniq([...genresFilters, genre.slug]));
                                                                    } else {
                                                                        setGenresFilters(genresFilters.filter((genreStr: string) => genreStr !== genre.slug));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }

                            {categories && categories.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setShowCategories(!showCategories)}>{i18next.t('header:search-filters.categories', { count_found: categoriesFilters.length > 0 ? `(${categoriesFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showCategories && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showCategories &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowCategories-${showCategories ? 'yes' : 'no'}`}>
                                                {categories.map((category: ItemCategory) => {
                                                    const disabled = defaultFilters?.categories?.includes(category.slug) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={category.id} className="">
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(category)}
                                                                checked={categoriesFilters.includes(category.slug)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setCategoriesFilters(uniq([...categoriesFilters, category.slug]));
                                                                    } else {
                                                                        setCategoriesFilters(categoriesFilters.filter((catSlug: string) => catSlug !== category.slug));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }

                            {subCategories && subCategories.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setSubShowCategories(!showSubCategories)}>{i18next.t('header:search-filters.sub-categories', { count_found: subCategoriesFilters.length > 0 ? `(${subCategoriesFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showSubCategories && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showSubCategories &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowSubCategories-${showSubCategories ? 'yes' : 'no'}`}>
                                                {subCategories.map((category: ItemSubCategory) => {
                                                    const disabled = defaultFilters?.subcategories?.includes(category.slug) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={category.id} className="">
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(category)}
                                                                checked={subCategoriesFilters.includes(category.slug)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setSubCategoriesFilters(uniq([...subCategoriesFilters, category.slug]));
                                                                    } else {
                                                                        setSubCategoriesFilters(subCategoriesFilters.filter((catSlug: string) => catSlug !== category.slug));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }

                            {collections && collections.length > 0 &&
                                <div className="border-b-[1px] pt-[20px] pb-[10px] px-[20px] md:px-0 md:pb-0 md:border-b-0">
                                    <button
                                        className="text-[16px] font-[500] flex flex-row items-center mb-[10px] cursor-pointer whitespace-nowrap"
                                        onClick={() => setShowCollections(!showCollections)}>{i18next.t('header:search-filters.collection', { count_found: collectionsFilters.length > 0 ? `(${collectionsFilters.length})` : '' })} <ChevronDownGreyIcon className={clsx('ml-1 transition-all', !showCollections && 'rotate-180')} />
                                    </button>
                                    <AnimatePresence mode="wait" initial={false}>
                                        {showCollections &&
                                            <motion.div
                                                initial={{ height: 0 }}
                                                animate={{ height: 'auto' }}
                                                exit={{ height: 0 }}
                                                className="overflow-hidden space-y-[10px] h-0"
                                                key={`ShowCollections-${showCollections ? 'yes' : 'no'}`}>
                                                {collections.map((collection: ItemCollection) => {
                                                    const disabled = defaultFilters?.collections?.includes(collection.slug) && isDefaultFilterFixed;
                                                    return (
                                                        <div key={collection.id} className="">
                                                            <Checkbox
                                                                borderColor="#D0D5DD"
                                                                borderWidth={1}
                                                                className={`cursor-pointer ${disabled ? 'opacity-60 bg-grey6' : ''}`}
                                                                labelClassName={disabled ? 'text-blue5' : ''}
                                                                labelStyle={{ marginLeft: 5, userSelect: 'none', fontSize: 16, paddingLeft: 5 }}
                                                                label={entityName(collection)}
                                                                checked={conditionsFilters.includes(collection.slug)}
                                                                disabled={disabled}
                                                                onChange={(checked: boolean) => {
                                                                    if (checked) {
                                                                        setCollectionsFilters(uniq([...collectionsFilters, collection.slug]));
                                                                    } else {
                                                                        setCollectionsFilters(collectionsFilters.filter((collectionStr: string) => collectionStr !== collection.slug));
                                                                    }
                                                                }}
                                                                icon={
                                                                    <div
                                                                        style={{
                                                                            padding: 1,
                                                                            width: '90%',
                                                                            height: '90%',
                                                                            backgroundColor: '#1D2939',
                                                                            borderRadius: 3
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </motion.div>}
                                    </AnimatePresence>
                                </div>
                            }
                        </div>
                }

            </div>
        </div>
    );
};

export default SearchFilters;
