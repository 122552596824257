import { PaginationResponseDto } from '@/api';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import i18next from 'i18n';
import isNil from 'lodash.isnil';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

type IPaginationProps = {
    transparent?: boolean
    data: PaginationResponseDto | undefined
    offsetChanged: (offset: number) => void;
    itemsPerPageChanged: (itemsPerPage: number) => void;
    defaultItemsPerPage: number;
    factor?: 4 | 10
};

const Pagination = (props: IPaginationProps) => {
    const { data, offsetChanged: pageChanged, transparent, itemsPerPageChanged, defaultItemsPerPage, factor = 10 } = props;

    const [itemsPerPageOptions, setItemsPerPageOptions] = useState(factor === 4 ? [
        { id: 6, value: 6, label: i18next.t('pagination.items-per-page', { count: 6 }) },
        { id: 12, value: 12, label: i18next.t('pagination.items-per-page', { count: 12 }) },
        { id: 24, value: 24, label: i18next.t('pagination.items-per-page', { count: 24 }) },
        { id: 48, value: 48, label: i18next.t('pagination.items-per-page', { count: 48 }) },
        { id: 96, value: 96, label: i18next.t('pagination.items-per-page', { count: 96 }) }
    ] : [
        { id: 5, value: 5, label: i18next.t('pagination.items-per-page', { count: 5 }) },
        { id: 10, value: 10, label: i18next.t('pagination.items-per-page', { count: 10 }) },
        { id: 20, value: 20, label: i18next.t('pagination.items-per-page', { count: 20 }) },
        { id: 40, value: 40, label: i18next.t('pagination.items-per-page', { count: 40 }) },
        { id: 80, value: 80, label: i18next.t('pagination.items-per-page', { count: 80 }) }
    ]);

    const defaultItemsPerPageCount = defaultItemsPerPage || (factor === 4 ? 12 : 20);

    useEffect(() => {
        if (defaultItemsPerPage && defaultItemsPerPageCount) {
            const arrOfOptions = itemsPerPageOptions.filter((option) => {
                if (defaultItemsPerPage && defaultItemsPerPageCount === option.id) {
                    return false;
                }
                return true;
            });
            arrOfOptions.unshift({ id: defaultItemsPerPageCount, value: defaultItemsPerPageCount, label: `${defaultItemsPerPageCount} Items per page` });
            setItemsPerPageOptions(arrOfOptions.sort((a, b) => a.value - b.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultItemsPerPage, defaultItemsPerPageCount]);

    const [itemsPerPage, setItemsPerPage] = useState<{ id: number; value: number; label: string }>({ id: defaultItemsPerPageCount, value: defaultItemsPerPageCount, label: `${defaultItemsPerPageCount} Items per page` });

    useEffect(() => {
        setItemsPerPage({ id: defaultItemsPerPage, value: defaultItemsPerPage, label: `${defaultItemsPerPage} Items per page` });
    }, [defaultItemsPerPage]);

    const startItem = data ? (data.offset) + 1 : undefined;
    const endItem = data ? Math.min((startItem || 0) + (data.limit) - 1, data.total) : undefined;
    const totalPages = data ? Math.ceil((data.total) / (data.limit)) : undefined;
    const currentPage = data ? Math.ceil(((data.offset) + (data.limit)) / (data.limit)) : undefined;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pagesToShow = useMemo(() => {
        if (isNil(currentPage) || isNil(totalPages)) {
            return [];
        }
        const priorityPages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
        const totalPossiblePages = [1, currentPage - 4, currentPage - 3, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, currentPage + 3, currentPage + 4, totalPages];

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const pagesToShow = totalPossiblePages.filter(
            (page, index, self) => page > 0 && page <= totalPages && self.indexOf(page) === index
        );
        pagesToShow.sort((a, b) => a - b);
        while (pagesToShow.length > 5) {
            if (currentPage < 3) {
                const firstNonPriroty = pagesToShow.findLastIndex((p) => !priorityPages.includes(p));
                pagesToShow.splice(firstNonPriroty, 1);
            } else {
                const firstNonPriroty = pagesToShow.findIndex((p) => !priorityPages.includes(p));
                pagesToShow.splice(firstNonPriroty, 1);
            }
        }
        return pagesToShow;
    }, [currentPage, totalPages]);

    if (isNil(currentPage) || isNil(totalPages) || isNil(startItem) || isNil(endItem) || isNil(data)) {
        return null;
    }

    return (
        <div className={clsx('flex items-center justify-between border-t border-gray-200  md:px-0 py-3 sm:px-6', { 'bg-white': !transparent })}>
            <div className="flex flex-1 justify-between sm:hidden mx-2">
                <button
                    onClick={() => pageChanged((currentPage - 2) * data.limit)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed"
                >
                    {i18next.t('pagination.previous')}
                </button>
                <button
                    onClick={() => pageChanged((currentPage) * data.limit)}
                    disabled={currentPage === totalPages}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed"
                >
                    {i18next.t('pagination.next')}
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <Select
                        styles={{
                            control: (base) => ({
                                ...base,
                                borderColor: '#cccccc',
                                '&:hover': {
                                    borderColor: '#cccccc',
                                },
                                boxShadow: 'none',
                            }),
                            option: (styles, { isFocused, isSelected }) => ({
                                ...styles,
                                background: isFocused
                                    ? '#001f308f'
                                    : isSelected
                                        ? '#2D5271'
                                        : undefined,
                                zIndex: 1
                            }),
                            menuList: (baseStyles) => ({
                                ...baseStyles,
                                padding: 0,
                            }),
                            menu: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: 100
                            }),
                        }}
                        defaultValue={itemsPerPage}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isSearchable={false}
                        name="color"
                        options={itemsPerPageOptions}
                        onChange={(selected) => {
                            if (selected) {
                                setItemsPerPage(selected);
                                itemsPerPageChanged(selected.value);
                            }
                        }}
                    />
                </div>
                <div>
                    <p className="text-sm text-gray-700">
                        {i18next.t('pagination.showing')} <span className="font-medium">{startItem}</span> {i18next.t('pagination.to')} <span className="font-medium">{endItem}</span> {i18next.t('pagination.of')} <span className="font-medium">{data?.total ?? 0}</span> {i18next.t('pagination.results')}
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                            onClick={() => pageChanged((currentPage - 2) * data.limit)}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-grey6 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed"
                        >
                            <span className="sr-only">{i18next.t('pagination.previous')}</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        {pagesToShow.map((page) => {
                            return (
                                <button
                                    key={page}
                                    onClick={() => pageChanged((page - 1) * data.limit)}
                                    aria-current="page"
                                    className={clsx(
                                        'relative inline-flex items-center px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                                        page === currentPage && 'bg-main !text-white hover:!text-main'
                                    )}
                                >
                                    {page}
                                </button>
                            );
                        })}
                        <button
                            onClick={() => pageChanged((currentPage) * data.limit)}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-grey6 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed"
                        >
                            <span className="sr-only">{i18next.t('pagination.next')}</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
