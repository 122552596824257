import { MetaApi } from '@/api';
import { apiConfiguration } from '@/api/config';

export const metaApi = new MetaApi(apiConfiguration);

export const getAllArtistQueryFn = async () => {
    return metaApi.metaControllerArtists();
};

export const getAllCategoriesQueryFn = async () => {
    return metaApi.metaControllerCategories();
};

export const getAllGenresQueryFn = async () => {
    return metaApi.metaControllerGenres();
};

export const getAllConditionsQueryFn = async () => {
    return metaApi.metaControllerConditions();
};
