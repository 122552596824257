import { ItemArtist, ItemCategory, ItemCollection, ItemCondition, ItemGenre, ItemSubCategory } from '@/api';
import { getAllCollectionsQueryFn } from '@/queries/collection';
import { getAllCategoriesQueryFn, getAllArtistQueryFn, getAllConditionsQueryFn, getAllGenresQueryFn } from '@/queries/meta';
import { MetaAvailableResponse } from '@/queries/search';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export const useMeta = (availableMeta?: MetaAvailableResponse) => {
    const { data: categories, isFetching: isFetchingCategories } = useQuery({
        queryKey: ['getAllCategoriesQueryFn'],
        queryFn: getAllCategoriesQueryFn
    });

    const { data: artists, isFetching: isFetchingArtists } = useQuery({
        queryKey: ['getAllArtistQueryFn'],
        queryFn: getAllArtistQueryFn
    });

    const { data: conditions, isFetching: isFetchingConditions } = useQuery({
        queryKey: ['getAllConditionsQueryFn'],
        queryFn: getAllConditionsQueryFn
    });

    const { data: genres, isFetching: isFetchingGenres } = useQuery({
        queryKey: ['getAllGenresQueryFn'],
        queryFn: getAllGenresQueryFn
    });

    const { data: collections, isFetching: isFetchingCollections } = useQuery({
        queryKey: ['getAllCollectionsQueryFn'],
        queryFn: getAllCollectionsQueryFn
    });

    const subCategories = useMemo(() => {
        return categories?.reduce((prev, category) => {
            return [...prev, ...category.sub_categories];
        }, [] as Array<ItemSubCategory>);
    }, [categories]);

    const findAvailable = useCallback((type: keyof MetaAvailableResponse, id: string) => {
        if (!availableMeta) {
            return undefined;
        }
        return availableMeta[type].find((a) => a.id === id)?.available || -1;
    }, [availableMeta]);

    const actualCategories = useMemo(() => {
        return categories?.map((e) => {
            return { ...e, available: findAvailable('category', e.id) } as ItemCategory & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [categories, findAvailable]);

    const actualGenres = useMemo(() => {
        return genres?.map((e) => {
            return { ...e, available: findAvailable('genres', e.id) } as ItemGenre & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [genres, findAvailable]);

    const actualConditions = useMemo(() => {
        return conditions?.map((e) => {
            return { ...e, available: findAvailable('conditions', e.id) } as ItemCondition & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [conditions, findAvailable]);

    const actualArtists = useMemo(() => {
        return artists?.map((e) => {
            return { ...e, available: findAvailable('artists', e.id) } as ItemArtist & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [artists, findAvailable]);

    const actualSubCategories = useMemo(() => {
        return subCategories?.map((e) => {
            return { ...e, available: findAvailable('sub_category', e.id) } as ItemSubCategory & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [subCategories, findAvailable]);

    const actualCollections = useMemo(() => {
        return collections?.map((e) => {
            return { ...e, available: findAvailable('collections', e.id) } as ItemCollection & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [collections, findAvailable]);

    return {
        categories: actualCategories,
        artists: actualArtists,
        conditions: actualConditions,
        genres: actualGenres,
        subCategories: actualSubCategories,
        collections: actualCollections,
        isFetching: isFetchingArtists || isFetchingCategories || isFetchingConditions || isFetchingGenres || isFetchingCollections
    };
};
